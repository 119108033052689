var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"transcript-wrapper mt-3"},[_c('v-menu',{attrs:{"absolute":"","offset-y":"","position-x":_vm.transcriptMenuX,"position-y":_vm.transcriptMenuY},model:{value:(_vm.showTranscriptMenu),callback:function ($$v) {_vm.showTranscriptMenu=$$v},expression:"showTranscriptMenu"}},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.dialogs.editor.state = true}}},[_c('v-list-item-title',[_vm._v("Edit")])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.deleteFragmentConfirm(_vm.dialogs.editor.exchangeIndex)}}},[_c('v-list-item-title',[_vm._v("Delete")])],1)],1)],1),(this.loading)?_c('div',{staticClass:"d-flex flex-column fill-height align-center justify-center black"},[(_vm.showStella)?_c('v-img',{attrs:{"src":require("@/assets/stella_large.gif"),"contain":"","max-width":"250"}}):_vm._e()],1):(_vm.items.length === 0)?_c('div',[_vm._m(0)]):_vm._l((_vm.items),function(item,exchangeIndex){return _c('div',{key:item.start,staticClass:"my-5 pr-3 text-justify"},[(item[0])?_c('div',{staticClass:"font-weight-bold"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-1 py-0",attrs:{"color":"secondary","dark":"","text":"","small":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.getSpeaker(item[0].voice))+" ")])]}}],null,true)},[_c('v-list',_vm._l((_vm.speakerNames),function(speaker,index){return _c('v-list-item',{key:index,attrs:{"link":""},on:{"click":function($event){return _vm.changeSpeaker(exchangeIndex, speaker)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.getSpeaker(speaker.value)))])],1)}),1)],1),_c('small',[_vm._v(" |  "+_vm._s(_vm.getTimeCode(item[0].start)))])],1):_vm._e(),_vm._l((item.map(function (o) {
    return {
      start: o.start,
      text: _vm.stripHTML(o.text),
    };
  })),function(fragment){return _c('span',{key:fragment.start,ref:("fragment-" + exchangeIndex),refInFor:true,staticClass:"fragment mr-1",domProps:{"innerHTML":_vm._s(fragment.text)},on:{"click":function($event){return _vm.seekToCaption(fragment)},"contextmenu":function($event){$event.preventDefault();return _vm.openTranscriptMenu(fragment, exchangeIndex, $event)}}})})],2)}),_c('v-dialog',{attrs:{"max-width":"700"},model:{value:(_vm.dialogs.editor.state),callback:function ($$v) {_vm.$set(_vm.dialogs.editor, "state", $$v)},expression:"dialogs.editor.state"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dark":"","dense":"","color":"primary"}},[_c('v-toolbar-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-pencil")]),_vm._v("Edit Caption ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialogs.editor.state = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),(_vm.dialogs.editor.fragment)?_c('v-card-text',{staticClass:"mt-5"},[_c('v-text-field',{attrs:{"label":"Caption","dense":"","hide-details":"","outlined":"","autofocus":""},model:{value:(_vm.dialogs.editor.fragment.text),callback:function ($$v) {_vm.$set(_vm.dialogs.editor.fragment, "text", $$v)},expression:"dialogs.editor.fragment.text"}})],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialogs.editor.state = false}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"secondary"},on:{"click":function($event){return _vm.saveFragment(_vm.dialogs.editor.exchangeIndex)}}},[_vm._v("Save")])],1)],1)],1)],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"font-italic text-center ma-5"},[_vm._v("Select a transcript under "),_c('strong',[_vm._v("Versions")]),_vm._v(" to edit")])}]

export { render, staticRenderFns }