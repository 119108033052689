<template>
  <div class="ml-5">
    <div class="d-flex justify-space-between">
      <!-- <v-text-field v-model="search" prepend-inner-icon="mdi-magnify" label="Search" solo-inverted dense clearable
        single-line hide-details class="mr-5"></v-text-field> -->
      <!-- <v-btn @click="alert('Hello')">Open Alert</v-btn> -->
      <v-menu :close-on-content-click="false" open-on-hover bottom offset-y>
        <template v-slot:activator="{ on }">
          <v-btn small color="primary" dark v-on="on">
            <v-icon small class="mr-1">mdi-numeric-1-box-multiple</v-icon>Versions<v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-group color="" v-for="transcription in versions" :key="transcription.transcriptionId" no-action
            :value="true" xprepend-icon="mdi-account-circle">
            <template v-slot:activator>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    Transcription <small>{{ transcription.transcription.transcriptionId }}</small>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ dayjs(transcription.transcription.created).format('MMM D, YYYY hh:mm a') }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list-item class="pl-5" v-for="version in transcription.versions" :key="version.versionNumber"
              @click="selectVersion(transcription.transcription.transcriptionId, version.versionNumber)">
              <v-list-item-icon class="mr-3">
                <v-icon small color="yellow" class="mt-2" title="Published Version"
                  v-if="preferredVersion.version === version.versionNumber && preferredVersion.transcriptionId === transcription.transcription.transcriptionId && !transcription.transcription.inactive">
                  mdi-star
                </v-icon>
                <v-icon class="mt-2 hidden" small v-else>
                  mdi-square
                </v-icon>

                <v-icon small color="green" class="mt-2 mr-1" title="Music Notched"
                  v-if="version.proto && version.proto.isNotched">mdi-music-off</v-icon>
                <v-icon class="mt-2 hidden" small v-else>
                  mdi-square
                </v-icon>

                <v-icon color="secondary" class="mt-2" title="Selected"
                  v-if="transcription.transcription.transcriptionId === selectedVersion.transcriptionId && version.versionNumber === selectedVersion.version">
                  mdi-chevron-right
                </v-icon>
                <v-icon class="mt-2 hidden" v-else>
                  mdi-square
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Version {{ version.versionNumber }}</v-list-item-title>

                <v-list-item-subtitle>{{ dayjs(version.updated).format('MMM D, YYYY hh:mm a') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-menu>

      <!-- <v-switch v-model="tableMode" label="Table View" hide-details inset dense class="my-0 mr-5"></v-switch> -->

      <v-btn :dark="items.length > 0" small color="primary" :loading="speakersLoading" :disabled="!items.length"
        @click="openSpeakerEditor">
        <v-icon class="mr-1" small>mdi-account-voice</v-icon>Speakers
      </v-btn>

      <v-menu v-model="menus.find" :close-on-content-click="false" bottom offset-y min-width="400" max-width="400">
        <template v-slot:activator="{ on }">
          <v-btn dark small color="primary" v-on="on">
            <v-icon class="mr-1" small>mdi-magnify</v-icon>Replace
          </v-btn>
        </template>

        <v-card>
          <div class="text-right">
            <v-btn icon @click="menus.find = false" class="mt-1 mr-1">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-text class="pt-1">
            <v-text-field v-model="findReplace.find" prepend-inner-icon="mdi-magnify" label="Find" color="secondary"
              hide-details dense outlined class="mb-2"></v-text-field>

            <v-text-field v-model="findReplace.replace" prepend-inner-icon="mdi-swap-horizontal" label="Replace With"
              color="secondary" hide-details dense outlined class="mb-2"></v-text-field>

            <v-checkbox v-model="findReplace.caseSensitive" append-icon="mdi-format-letter-case-upper" label="Match Case"
              color="secondary" dense hide-details class="mt-0 mb-4"></v-checkbox>


            <div class="d-flex justify-space-between mt-2">
              <v-btn small dark color="accent" :disabled="findReplace.results.length === 0"
                @click="replaceOne(findReplace.index)">Replace</v-btn>
              <v-btn small dark color="accent2" :disabled="findReplace.results.length === 0" @click="replaceAll">Replace
                All</v-btn>
            </div>

            <!-- <v-btn small color="primary" :disabled="findReplace.index >= findReplace.results.length"
              @click="findNext">Find<span v-if="findReplace.index > 0">&nbsp;Next</span> {{ findReplace.index
              }}</v-btn>

            <v-btn small color="primary" :disabled="findReplace.index >= findReplace.results.length"
              @click="findResults">Find</v-btn> -->

            <v-simple-table class="mt-5 mb-2 scrollable">
              <tr v-for="result in unreplacedResults" :key="`blurb-${result.index}-${result.start}-${result.end}`">
                <td class="pb-2">
                  {{ result.start >= findReplace.highlight.includeBefore ? '...' : '' }}<span
                    v-html="highlightMatch(result)"></span>{{ result.blurb.length - findReplace.highlight.includeAfter >= result.end ? '...' : '' }}
                </td>
                <!-- <td>{{ result }}</td> -->
                <!-- <td><v-btn icon small @click="seekToCaption({start: result.start})"><v-icon>mdi-speaker-wireless</v-icon></v-btn></td> -->
              </tr>
            </v-simple-table>

            <small><em>Found {{ unreplacedResults.length }} {{ unreplacedResults.length === 1 ? 'occurrence' :
              'occurrences'
            }}</em></small>

          </v-card-text>
        </v-card>
      </v-menu>

      <v-btn :dark="isDirty" small color="primary" :disabled="!isDirty" @click="ccSave()">
        <v-icon class="mr-1" small>mdi-floppy</v-icon>Save
      </v-btn>

      <v-btn dark small color="success" v-if="showCCPublish" @click="ccApprove" :loading="ccLoading">
        <v-icon class="mr-1">mdi-closed-caption</v-icon>Publish
      </v-btn>

      <v-btn dark small color="error" v-if="showCCUnpublish" @click="ccReject" :loading="ccLoading">
        <v-icon class="mr-1">mdi-closed-caption</v-icon>Unpublish
      </v-btn>
    </div>

    <v-data-table v-model="selectedRows" :headers="headers" :items="itemsLimited" item-key="start" :search="search"
      :loading="loading" show-selectx disable-sort dense :items-per-page="-1" class="mt-3" id="virtual-scroll-table"
      @dblclick:row="selectCaption" v-scroll:#virtual-scroll-table="onScroll" v-if="tableMode">
      <template v-if="start > 0" v-slot:body.prepend>
        <tr>
          <td :colspan="headers.length" :style="'padding-top:' + startHeight + 'px'"></td>
        </tr>
      </template>
      <template v-if="start + perPage < this.items.length" v-slot:body.append>
        <tr>
          <td :colspan="headers.length" :style="'padding-top:' + endHeight + 'px'"></td>
        </tr>
      </template>

      <template v-slot:item.start="{ item }">
        {{ getTimeCode(item.start) }}
      </template>

      <template v-slot:item.end="{ item }">
        {{ getTimeCode(item.end) }}
      </template>

      <template v-slot:item.voice="props">
        <v-edit-dialog :return-value.sync="props.item.voice" large persistent>
          {{ getSpeaker(props.item.voice) }}
          <template v-slot:input>
            <v-select v-model="props.item.voice" :items="speakerNames" label="Speakers" outlined class="mt-5"></v-select>
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:item.text="props">
        <v-edit-dialog :return-value.sync="props.item.text" large persistent @save="saveItem">
          <span v-html="props.item.text"></span>
          <template v-slot:input>
            <v-textarea v-model="props.item.text" :rules="[]" label="Edit Caption" rows="3" counter outlined class="mt-5">
            </v-textarea>
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small @click="deleteItemConfirm(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>

    <TranscriptEditor :items="itemsTranscript" :speakerNames="speakerNames" :currentTime="currentTime" :loading="loading"
      :isDirtyProp="isDirty" @seekreq="seekToCaption" @isDirty="transcriptDirtyChanged" v-else />

    <v-dialog v-model="dialogs.speakerEditor.state" max-width="700">
      <v-card>
        <v-toolbar dark dense color="primary">
          <v-toolbar-title>
            <v-icon class="mr-2">mdi-account-voice</v-icon>Edit Speaker Names
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogs.speakerEditor.state = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-5">
          <p>Edit the available speaker names below, if they are known.</p>

          <v-list>
            <v-list-item v-for="(speakerName, index) in dialogs.speakerEditor.speakerNames" :key="index">
              <v-list-item-content class="py-2">
                <div class="d-flex">
                  <v-select v-model="dialogs.speakerEditor.speakerNamesEdited[index]" :items="guestsAndHosts"
                    :label="`Speaker ${index + 1}`" color="secondary" dense outlined hide-details class="mr-5"></v-select>

                  <v-text-field v-model="dialogs.speakerEditor.speakerNamesCustom[index]" :label="`Speaker ${index + 1}`"
                    color="secondary" dense outlined hide-details
                    v-if="dialogs.speakerEditor.speakerNamesEdited[index] === '-- Custom --'"></v-text-field>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogs.speakerEditor.state = false">Cancel</v-btn>
          <v-btn text color="secondary" @click="saveSpeakers">Assign</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogs.confirmDelete.state" max-width="500">
      <v-card>
        <v-card-title>Delete Caption</v-card-title>
        <v-card-text v-if="dialogs.confirmDelete.item">
          <p class="subtitle-1">
            Are you sure you want to delete the following caption?
          </p>

          <v-sheet color="grey darken-3" class="subtitle-1 pa-5">
            <strong>{{ getSpeaker(dialogs.confirmDelete.item.voice) }} |
              {{ getTimeCode(dialogs.confirmDelete.item.start) }}</strong>
            <div>{{ stripHTML(dialogs.confirmDelete.item.text) }}</div>
          </v-sheet>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogs.confirmDelete.state = false">Cancel</v-btn>
          <v-btn text color="error" @click="deleteItem(dialogs.confirmDelete.item)">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <Alert v-model="dialogs.alert.state" :message="dialogs.alert.message" /> -->
    <!-- <Alert :state="dialogs.alert.state" :message="dialogs.alert.message"></Alert> -->

    <v-dialog v-model="dialogs.alert.state" max-width="500" @click:outside="dialogs.alert.state = false">
      <v-card color="primary" dark>
        <v-card-title class="d-flex justify-space-between">
          Alert
          <v-btn icon @click="dialogs.alert.state = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <span v-text="dialogs.alert.message"></span>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn @click="dialogs.alert.state = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
#virtual-scroll-table {
  max-height: 400px;
  overflow: auto;
}

.scrollable {
  max-height: 400px;
  overflow: auto;
}

.hidden {
  visibility: hidden;
}
</style>

<script>
import dayjs from "dayjs";
import axios from "axios";
import md5 from "crypto-js/md5";
import { isNumber, isNaN, isNull, isUndefined, isString, filter, findIndex, map, union, sortBy } from "lodash";
import TranscriptEditor from "@/components/TranscriptEditor";
// import Alert from "@/components/Alert";

export default {
  name: "Captions",

  components: {
    TranscriptEditor,
    // Alert
  },

  props: ["bus", "blobServer", "showId", "ind", "entityId", "episodeId", "currentTime", "showInfo", "jwtToken", "jwtSource"],

  data: () => ({
    loading: false,
    ccLoading: false,
    speakersLoading: false,
    isDirty: false,
    search: null,
    sortVersions: true,
    versions: [],
    preferredVersion: {
      transcriptionId: null,
      version: null
    },
    selectedVersion: {
      transcriptionId: null,
      version: null
    },
    headers: [
      { text: "Start Time", value: "start", width: 90 },
      { text: "Speaker", value: "voice", width: 150 },
      { text: "Text", value: "text" },
      { text: "", value: "actions" },
    ],
    items: [],
    itemsTranscript: [],
    selectedRows: [],
    captions: null,
    start: 0,
    timeout: null,
    rowHeight: 24,
    perPage: 25,
    tableMode: false,
    findReplace: {
      results: [],
      index: -1,
      find: '',
      replace: null,
      caseSensitive: true,
      fragmentBreak: '\u0001',
      highlight: {
        includeBefore: 20,
        includeAfter: 20
      },
    },
    menus: {
      find: false,
    },
    dialogs: {
      alert: {
        state: false,
        message: null,
      },
      transcript: {
        state: false,
      },
      speakerEditor: {
        state: false,
        speakerNames: [],
        speakerNamesEdited: [],
        speakerNamesCustom: [],
        annotations: {
          hosts: [],
          guests: []
        }
      },
      ccEditor: {
        state: false,
        line: null,
      },
      confirmDelete: {
        state: false,
        item: null,
      },
    },
  }),

  watch: {
    async jwtToken() {
      if (this.jwtToken) {
        await this.loadVersions();
        await this.loadCaptions();
      }
    },

    itemsTranscript() {
      // take the grouped transcript view and flatten them again
      const items = [];
      for (let exchange of this.itemsTranscript) {
        for (let item of exchange) {
          items.push(item);
        }
      }
      this.items = items;

      // regenerate the VTT file for the editor to load by URL
      this.regenerateVTT();
    },

    // currentTime() {
    //   const foundIndex = findIndex(this.items, (o) => {
    //     return (
    //       this.currentTime >= Math.round(o.start / 1000) &&
    //       this.currentTime < Math.round(o.end / 1000)
    //     );
    //   });
    //   if (foundIndex !== -1) this.selectedRows = [this.items[foundIndex]]; //for table view
    // },

    "findReplace.find"() {
      this.findResults();
    },

    "findReplace.caseSensitive"() {
      this.findResults();
    }
  },

  computed: {
    dayjs() {
      return dayjs;
    },

    guestsAndHosts() {
      return union(['-- Custom --'], this.dialogs.speakerEditor.annotations.hosts || [], this.dialogs.speakerEditor.annotations.guests || []).sort();
    },

    itemsLimited() {
      return this.items.slice(this.start, this.perPage + this.start);
    },

    startHeight() {
      return this.start * this.rowHeight - 32;
    },

    endHeight() {
      return this.rowHeight * (this.items.length - this.start);
    },

    speakerNames() {
      const voices = this.items.map(o => {
        return { text: isNaN(parseInt(o.voice)) ? this.getSpeaker(o.voice) : o.voice, value: o.voice };
      });

      //get a distinct array of voices objects
      let result = [];
      const map = new Map();
      for (const voice of voices) {
        if (!map.has(voice.value)) {
          map.set(voice.value, true);
          result.push(voice);
        }
      }

      result = sortBy(result, o => {
        return parseInt(o.value) || parseInt(o.value.replace('Speaker ', '')) || 0;
      });

      return result;
    },

    episodeGUID() {
      return this.md5toUniqueId(md5(`episodeVersion${this.episodeId}`).toString());
    },

    showCCPublish() {
      return !!this.selectedVersion.transcriptionId && (this.selectedVersion.transcriptionId !== this.preferredVersion.transcriptionId || this.selectedVersion.version !== this.preferredVersion.version);
    },

    showCCUnpublish() {
      return !this.showCCPublish;
    },

    unreplacedResults() {
      return filter(this.findReplace.results, { replaced: false });
    }
  },

  methods: {
    checkUnauthorized(err) {
      if (err.response && err.response.status) {
        if (err.response.status === 401) {
          this.$router.push({ name: 'Login', query: { redirect: window.location.href } });
        }
      }
    },

    async loadVersions() {
      try {
        const response = await axios.get(`/entity/${this.entityId || window.__env.ccEntityPrefix + this.episodeGUID}/transcriptions/detailsWithPreferredVersion`, {
          headers: {
            Authorization: "Bearer " + this.jwtToken,
            "X-Source": this.jwtSource
          }
        });

        //augment the transcriptions and versions with protobuf metadata
        for (const transcription of response.data.details) {
          for (const version of transcription.versions) {
            try {
              if (version.data) {
                const proto = await axios.post('/proto-decode', {
                  message: version.data
                }, {
                  headers: {
                    Authorization: "Bearer " + this.jwtToken,
                    "X-Source": this.jwtSource
                  }
                });
                version.proto = proto.data.decoded.data;
              }
            } catch (err) {
              // console.error(err);
            }
          }
        }

        // sort versions by version number
        if (this.sortVersions) {
          for (const transcript of response.data.details) {
            transcript.versions = sortBy(transcript.versions, o => {
              return parseInt(o.versionNumber) || 0;
            });
          }
        }

        this.versions = response.data.details;

        // set the preferredVersion
        this.preferredVersion = {
          transcriptionId: response.data.preferredVersion?.transcriptionId || null,
          version: isNumber(response.data.preferredVersion?.versionNumber) ? response.data.preferredVersion?.versionNumber : null
        };

        this.$emit('hasCaptions', true);
      } catch (err) {
        this.checkUnauthorized(err);
        console.error(err);

        this.$emit('hasCaptions', false);
      }
    },

    async loadCaptions() {
      try {
        this.loading = true;

        // determine current selectedVersion from API if not specified
        if (!this.selectedVersion.transcriptionId || isNaN(this.selectedVersion.version)) {
          const responsePV = await axios.get(`/entity/${this.entityId || window.__env.ccEntityPrefix + this.episodeGUID}/preferredVersion`, {
            headers: {
              Authorization: "Bearer " + this.jwtToken,
              "X-Source": this.jwtSource
            },
            validateStatus: status => {
              return status >= 200 && status < 300 || status === 404;
            },
          });

          if (!isUndefined(responsePV.data.transcriptionId) && !isUndefined(responsePV.data.versionNumber)) {
            this.selectedVersion = {
              transcriptionId: responsePV.data.transcriptionId,
              version: responsePV.data.versionNumber
            };

            this.preferredVersion = {
              transcriptionId: responsePV.data.transcriptionId,
              version: responsePV.data.versionNumber
            };
          } else {
            // try to get the latest transcription and latest version if we don't have a preferred
            // console.log('No preferred version found, trying another` way...')

            //sort this.versions by transcription.created DESC
            const sortedTranscriptions = sortBy(this.versions, o => o.transcription.created).reverse();

            if (sortedTranscriptions[0]) {
              // console.log(`Found the most recent transcript: ${sortedTranscriptions[0].transcription.transcriptionId}`);

              //sort versions array by highest versionNumber
              const sortedVersions = sortBy(sortedTranscriptions[0].versions, 'versionNumber').reverse();
              const initialVersion = sortedVersions[0];

              if (initialVersion) {
                // console.log(`Found the most recent version: ${initialVersion.versionNumber}`);
                this.selectedVersion = {
                  transcriptionId: initialVersion.transcriptionId,
                  version: initialVersion.versionNumber
                };
              }
            } else {
              // console.log('Still unable to find a preferred version :(');
            }
          }
        }

        //bail out if these are null/undefined
        if (!this.selectedVersion.transcriptionId || isNull(this.selectedVersion.version) || isUndefined(this.selectedVersion.version)) {
          this.loading = false;
          return;
        }

        const response = await axios.get(`/captions/json/${this.entityId || window.__env.ccEntityPrefix + this.episodeGUID}/transcriptions/${this.selectedVersion.transcriptionId}/version/${this.selectedVersion.version}`, {
          headers: {
            Authorization: "Bearer " + this.jwtToken,
            "X-Source": this.jwtSource
          }
        });

        if (!response.data.success) throw new Error('Error from API');

        // pull out the data property in each cue
        this.items = map(filter(response.data.transcript, { type: "cue" }), 'data');

        this.generateTranscript();

        // this.$emit('hasCaptions', true);
        this.loading = false;

        //if we're loading a non-preferred version, consider it dirty so the user can click the CC Approve button to make it preferred
        // this.isDirty = (!this.selectedVersion.transcriptionId || isNaN(this.selectedVersion.version)) ? false : true;
        //@todo fix isDirty
      } catch (err) {
        this.checkUnauthorized(err);
        console.error(err);
        // this.$emit('hasCaptions', false);
        this.loading = false;
        this.isDirty = false; //?
      }
    },

    async loadAnnotations() {
      try {
        const { data } = await axios.get(`/mddb/v3/episode?episodeShortId=${parseInt(this.showInfo.episode.shortId, 16) || ''}&showShortId=${parseInt(this.showInfo.shortId, 16)}`, {
          headers: {
            Authorization: "Bearer " + this.jwtToken,
            "X-Source": this.jwtSource
          }
        });

        const hosts = union(data.show.hosts || [], data.episode.hosts || []).sort();
        const guests = union(data.show.guests || [], data.episode.guests || []).sort();

        this.dialogs.speakerEditor.annotations.hosts = hosts;
        this.dialogs.speakerEditor.annotations.guests = guests;
      } catch (err) {
        this.checkUnauthorized(err);
        console.error(err);
      }
    },

    async ccApprove() {
      try {
        this.ccLoading = true;

        //save first, if needed
        if (this.isDirty) await this.ccSave(true);

        await axios.post(`/entity/${this.entityId || window.__env.ccEntityPrefix + this.episodeGUID}/activate?showId=${this.showId}&episodeId=${this.episodeId}`, {
          typedEntityId: `${this.entityId || window.__env.ccEntityPrefix + this.episodeGUID}`,
          transcriptionId: this.selectedVersion.transcriptionId,
          version: this.selectedVersion.version,
          // activeVersionDescription: `Approved by xxx on ${dayjs().format('MMM D, YYYY hh:mm a')}`, //this gets overwritten by the API server to grab user info
          inactive: false
        }, {
          headers: {
            Authorization: "Bearer " + this.jwtToken,
            "X-Source": this.jwtSource
          }
        });

        this.ccLoading = false;

        // this.$nextTick(async () => {
        await this.loadVersions();
        await this.loadCaptions();
        // });

        this.alert('This transcript has been published successfully as the preferred version.');
      } catch (err) {
        this.checkUnauthorized(err);
        console.error(err);

        this.ccLoading = false;

        // this.$nextTick(async () => {
        await this.loadVersions();
        await this.loadCaptions();
        // });
      }
    },

    async ccReject() {
      try {
        this.ccLoading = true;

        await axios.post(`/entity/${this.entityId || window.__env.ccEntityPrefix + this.episodeGUID}/transcriptions/${this.selectedVersion.transcriptionId}?showId=${this.showId}&episodeId=${this.episodeId}`, {
          typedEntityId: `${this.entityId || window.__env.ccEntityPrefix + this.episodeGUID}`,
          transcriptionId: this.selectedVersion.transcriptionId,
          version: this.selectedVersion.version,
          // activeVersionDescription: `Approved by xxx on ${dayjs().format('MMM D, YYYY hh:mm a')}`, //this gets overwritten by the API server to grab user info
          inactive: true
        }, {
          headers: {
            Authorization: "Bearer " + this.jwtToken,
            "X-Source": this.jwtSource
          }
        });

        this.ccLoading = false;

        this.loadVersions();
        this.loadCaptions();

        this.alert('This transcript has been successfully unpublished.');
      } catch (err) {
        this.checkUnauthorized(err);
        console.error(err);

        this.ccLoading = false;

        this.loadVersions();
        this.loadCaptions();
      }
    },

    async ccSave(suppress) {
      try {
        const response = await axios.put(`/entity/${this.entityId || window.__env.ccEntityPrefix + this.episodeGUID}/transcriptions/${this.selectedVersion.transcriptionId}`, {
          captions: this.items,
        }, {
          headers: {
            Authorization: "Bearer " + this.jwtToken,
            "X-Source": this.jwtSource
          }
        });

        if (!suppress) this.alert('Closed Captions have been saved successfully.');

        //advance to select this new version and mark the captions clean
        this.selectedVersion = {
          transcriptionId: response.data.transcriptionId,
          version: response.data.versionNumber
        };
        this.isDirty = false;

        if (!suppress) await this.loadVersions();
        if (!suppress) await this.loadCaptions();
      } catch (err) {
        this.checkUnauthorized(err);
        console.error(err);
        this.alert('An error occurred while attempting to save Closed Captions.');

        if (!suppress) this.loadVersions();
        if (!suppress) this.loadCaptions();
      }
    },

    async selectVersion(transcriptionId, version) {
      //check if isDirty and prompt user to confirm they want to discard changes
      const confirm = this.isDirty ? window.confirm('Do you want to discard your currently pending changes?') : true;
      if (confirm) {
        this.selectedVersion = {
          transcriptionId,
          version
        };
        await this.loadCaptions();
      }
    },

    generateTranscript() {
      let lastSpeaker;
      this.itemsTranscript = this.groupFragments(this.items.map(o => {
        o.voice = o.voice ? o.voice : lastSpeaker ? lastSpeaker : null;
        lastSpeaker = o.voice;
        return o;
      }));
    },

    groupFragments(lines) {
      const groupedLines = [];
      let currentLines = [];
      let lastVoice;
      for (const line of lines) {
        if (lastVoice && lastVoice !== line.voice) {
          groupedLines.push(currentLines);
          currentLines = [];
        }
        currentLines.push(line);
        lastVoice = line.voice;
      }

      //make sure we don't chop off the last speaker by not pushing the end of the buffer at the end of the loop
      groupedLines.push(currentLines);

      //if they all grouped under one voice, we need to return them
      if (groupedLines.length === 0) groupedLines.push(currentLines);

      return groupedLines;
    },

    async openSpeakerEditor() {
      this.speakersLoading = true;

      //only load the speakers from MDDB once
      if (this.dialogs.speakerEditor.annotations.hosts.length === 0 && this.dialogs.speakerEditor.annotations.guests.length === 0) await this.loadAnnotations();

      this.dialogs.speakerEditor.speakerNames = this.speakerNames;

      // preload the speakerNamesEdited array with the current speakers
      for (const [index, editedSpeakerName] of this.dialogs.speakerEditor.speakerNames.entries()) {

        //find a match among guests and hosts list, if match found, use it, else use custom field
        if (this.guestsAndHosts.indexOf(editedSpeakerName.text) !== -1 || !isNaN(parseInt(editedSpeakerName.value))) {
          this.dialogs.speakerEditor.speakerNamesEdited[index] = editedSpeakerName.text;
        } else {
          this.dialogs.speakerEditor.speakerNamesEdited[index] = '-- Custom --';
          this.dialogs.speakerEditor.speakerNamesCustom[index] = editedSpeakerName.text;
        }
      }

      this.speakersLoading = false;
      this.dialogs.speakerEditor.state = true;
    },

    saveSpeakers() {
      // determine the old value so we know which ones to update
      const speakerNamesToUpdate = {};
      for (const [index, speakerNameEdited] of this.dialogs.speakerEditor.speakerNamesEdited.entries()) {
        if (this.dialogs.speakerEditor.speakerNames[index].value, this.dialogs.speakerEditor.speakerNames[index].text) {
          //if set to custom, use the speaker name entered or use Speaker x
          speakerNamesToUpdate[this.dialogs.speakerEditor.speakerNames[index].value] = (speakerNameEdited === '-- Custom --') ?
            this.dialogs.speakerEditor.speakerNamesCustom[index] && this.dialogs.speakerEditor.speakerNamesCustom[index].trim() !== '' ? this.dialogs.speakerEditor.speakerNamesCustom[index] : `Speaker ${parseInt(index) + 1}` :
            speakerNameEdited && speakerNameEdited.trim() !== '' ? speakerNameEdited : `Speaker ${parseInt(index) + 1}`;

        }
      }

      this.setNewSpeakers(speakerNamesToUpdate);
      this.dialogs.speakerEditor.state = false;

      this.regenerateVTT();
      this.isDirty = true;
    },

    setNewSpeakers(newSpeakers) {
      //update the speaker name for all captions
      for (const [index, speaker] of Object.entries(newSpeakers)) {
        if (!speaker) continue;

        const foundCaptions = filter(this.items, { voice: isNumber(index) ? index.toString() : index });
        foundCaptions.map(o => {
          o.voice = speaker;
          return o;
        });
      }
    },

    saveItem() {
      this.regenerateVTT();
      this.isDirty = true;
    },

    openCCEditor(line) {
      this.dialogs.ccEditor.lines = [line];
      this.dialogs.ccEditor.state = true;
    },

    openCCEditorMultiple() {
      const lines = [];
      this.dialogs.ccEditor.lines = lines;
      this.dialogs.ccEditor.state = true;
    },

    deleteItemConfirm(item) {
      this.dialogs.confirmDelete.item = item;
      this.dialogs.confirmDelete.state = true;
    },

    deleteItem(item) {
      // delete row from captions
      const foundIndex = findIndex(this.items, { start: item.start });
      if (foundIndex !== -1) this.items.splice(foundIndex, 1);

      // regenerate the transcript version from the updated captions
      this.generateTranscript();

      // regenerate the VTT file for the editor to load by URL
      this.regenerateVTT();
      this.isDirty = true;

      this.dialogs.confirmDelete.state = false;
    },

    async regenerateVTT() {
      const response = await axios.post(
        `/captions/generate/vtt/${this.entityId || this.episodeId}`,
        {
          captions: this.items,
        },
        {
          headers: {
            Authorization: "Bearer " + this.jwtToken,
            "X-Source": this.jwtSource
          },
        }
      );

      //update VTT track
      this.$emit("updateCaptions", response.data.vttHandle);
    },

    transcriptDirtyChanged(isDirty) {
      //when transcript isDirty flag changes on @isDirty, update our local flag
      this.isDirty = isDirty;
    },

    stripHTML(str) {
      if (typeof str !== "string") return str;
      const regex = /(<([^>]+)>)/gi;
      return str.replace(regex, "");
    },

    selectCaption(e, data) {
      this.seekToCaption(data.item);
    },

    seekToCaption(item) {
      this.$emit("seekreq", item.start / 1000);
    },

    async findResults() {
      this.findReplace.index = 0;

      let results = [];
      const search = this.findReplace.caseSensitive ? this.findReplace.find : this.findReplace.find.toLowerCase();

      if (isString(search) && search.length === 0) {
        this.findReplace.results = results;
        return;
      }

      for (const [blurbIndex, captions] of this.itemsTranscript.entries()) {
        let blurb = '';
        for (const caption of captions) {
          blurb += caption.text + this.findReplace.fragmentBreak;
        }
        blurb = blurb.substring(0, blurb.length - 1);

        const blurbResults = await this.findOccurences(blurbIndex, blurb, search);
        if (blurbResults.length > 0) {
          results = results.concat(blurbResults);
        }
      }

      this.findReplace.results = results;
    },

    async findOccurences(blurbIndex, blurb, search) {
      const blurbCleaned = blurb.replace(new RegExp(this.escapeRegExp(this.findReplace.fragmentBreak), 'g'), ' '); //eslint-disable-line no-control-regex
      const results = [];
      let lastIndex = -1;

      const limit = 1000;
      let counter = 0;

      do {
        counter++;
        const found = this.findReplace.caseSensitive ? blurbCleaned.indexOf(this.entityEscape(search), lastIndex + 1) : blurbCleaned.toLowerCase().indexOf(search, lastIndex + 1);
        lastIndex = found;

        if (found !== -1) {
          results.push({
            index: blurbIndex,
            blurb: blurb,
            start: found,
            end: found + this.entityEscape(search).length,
            replaced: false
          });
        }

      } while (lastIndex !== -1 && counter < limit);

      return results;
    },

    findNext() {
      if (this.findReplace.index < this.findReplace.results.length - 1) this.findReplace.index++;

      // this.findResults();
    },

    async replaceOne() {
      const index = this.findReplace.index;
      const replaceInstance = this.findReplace.results[index];

      if (replaceInstance) {
        const prefix = replaceInstance.blurb.substring(0, replaceInstance.start);
        const match = replaceInstance.blurb.substring(replaceInstance.start, replaceInstance.end);
        const suffix = replaceInstance.blurb.substring(replaceInstance.end);

        // detect if a fragment break character exists in the match and add a fragment break after the replace string        
        const newBlurb = match.indexOf(this.findReplace.fragmentBreak) !== -1 ? prefix + this.findReplace.replace + this.findReplace.fragmentBreak + suffix : prefix + this.findReplace.replace + suffix;
        const newFragments = newBlurb.split(this.findReplace.fragmentBreak);

        for (const [fragmentIndex, fragment] of this.itemsTranscript[replaceInstance.index].entries()) {
          fragment.text = newFragments[fragmentIndex];
        }

        // remove the match result
        replaceInstance.replaced = true;
        this.findReplace.index++;

        this.isDirty = true;
        this.regenerateVTT();
      }
    },

    async replaceAll() {
      const confirmAll = confirm(`Are you sure you want to replace all ${this.unreplacedResults.length} occurrences?`);

      if (confirmAll) {
        for (const index in this.findReplace.results) {
          console.log(`Replacing result ${index}`);
          await this.replaceOne();
        }
      }
    },

    highlightMatch(match) {
      const blurbCleaned = match.blurb.replace(new RegExp(this.escapeRegExp(this.findReplace.fragmentBreak), 'g'), ' '); //eslint-disable-line no-control-regex
      return `${blurbCleaned.substring(match.start - this.findReplace.highlight.includeBefore || 0, match.start)}<span class="yellow black--text">${blurbCleaned.substring(match.start, match.end)}</span>${blurbCleaned.substring(match.end, match.end + this.findReplace.highlight.includeAfter)}`;
    },

    getTimeCode(ms) {
      return dayjs.duration(ms, "milliseconds").format("HH:mm:ss");
    },

    getSpeaker(voice) {
      return !isNaN(parseInt(voice)) ? "Speaker " + (parseInt(voice) + 1) : voice;
    },

    alert(message) {
      this.dialogs.alert.message = message;
      this.dialogs.alert.state = true;
    },

    md5toUniqueId(md5Hash) {
      if (typeof md5Hash !== 'string') return;
      return (
        md5Hash.substring(0, 8) +
        '-' +
        md5Hash.substring(8, 12) +
        '-' +
        md5Hash.substring(12, 16) +
        '-' +
        md5Hash.substring(16, 20) +
        '-' +
        md5Hash.substring(20)
      ).toLowerCase();
    },

    escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    },

    entityEscape(string) {
      string = string.replace(/</g, '&lt;');
      string = string.replace(/>/g, '&gt;');      
      return string;
    },

    onScroll(e) {
      // debounce if scrolling fast
      this.timeout && clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        const { scrollTop } = e.target;
        const rows = Math.ceil(scrollTop / this.rowHeight);

        this.start =
          rows + this.perPage > this.items.length
            ? this.items.length - this.perPage
            : rows;

        this.$nextTick(() => {
          e.target.scrollTop = scrollTop;
        });
      }, 20);
    },
  },

  created() {
    this.bus.$on('ccApprove', () => {
      this.ccApprove();
    });
  },

  mounted() {
    axios.defaults.baseURL = window.__env.baseURL;

    //moved init calls to the jwtToken watch function, because we have a race condition where the token isn't always ready by this time
    if (this.jwtToken) {
      this.loadVersions();
      this.loadCaptions();
    }
  },
};
</script>
