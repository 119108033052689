<template>
    <div>
        <v-card class="mx-0" :loading="loading" dark>
            <div class="d-flex justify-space-between align-center primary">
                <v-img
                    :src="artworkThumbnail"
                    min-height="60"
                    min-width="60"
                    max-height="60"
                    max-width="60"
                    contain
                    @click="dialogs.artwork.state = true"
                    v-if="artworkThumbnail"
                ></v-img>
                <v-sheet class="accent2 d-flex justify-center align-center" height="60" width="60" v-else>
                    <v-icon>mdi-music-note</v-icon>
                </v-sheet>

                <div v-if="metadata.artist || metadata.title" class="flex-grow-1 mx-5 d-block" style="width: 500px">
                    <div class="text-truncate" v-if="metadata.artist && metadata.title" :title="`${metadata.artist} - ${metadata.title}`">{{ metadata.artist }} - {{ metadata.title }}</div>
                    <div class="text-truncate" v-else :title="`${metadata.title || metadata.artist || ''}`">{{ metadata.title || metadata.artist || '' }}</div>

                    <div class="caption">
                        {{ currentTime }} / {{ duration }}
                    </div>

                    <div class="text-no-wrap grey darken-2">
                        <v-progress-linear
                            v-model="currentPercentage"
                            :buffer-value="currentBuffer"
                            color="secondary"
                            height="6"
                            @change="seek"
                            :active="!!this.metadata.duration"
                            rounded
                            class="mt-1"
                        ></v-progress-linear>
                    </div>
                </div>

                <div class="text-no-wrap">

                    <v-menu offset-y v-if="levels">
                        <template v-slot:activator="{ on, attrs }">
                            <v-chip color="secondary" x-small v-bind="attrs" v-on="on" v-if="selectLevel === -1">AUTO <v-icon small class="mr-n1">mdi-chevron-down</v-icon></v-chip>
                            <v-chip color="secondary" x-small v-bind="attrs" v-on="on" v-else>{{levels[selectLevel] ? levels[selectLevel].name : currentLevel}} <v-icon small class="mr-n1">mdi-chevron-down</v-icon></v-chip>
                        </template>
                        <v-list>
                            <v-list-item
                                v-for="(level, index) in availableLevels"
                                :key="index"
                                @click="selectBitrate(level.index)"
                            >
                                <v-list-item-title>{{ level.name }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <v-btn icon @click="backward" :disabled="currentSegment === 0">
                        <v-icon>mdi-skip-backward</v-icon>
                    </v-btn>

                    <v-btn icon @click="rewind">
                        <v-icon>mdi-rewind-10</v-icon>
                    </v-btn>

                    <v-btn icon @click="pause" v-if="isPlaying">
                        <v-icon>mdi-pause</v-icon>
                    </v-btn>
                    <v-btn icon @click="play" v-else>
                        <v-icon>mdi-play</v-icon>
                    </v-btn>

                    <v-btn icon @click="fastForward">
                        <v-icon>mdi-fast-forward-10</v-icon>
                    </v-btn>

                    <v-btn icon @click="forward" :disabled="currentSegment >= totalSegments - 1">
                        <v-icon>mdi-skip-forward</v-icon>
                    </v-btn>
                </div>

            </div>
        </v-card>

        <v-dialog
            v-model="dialogs.artwork.state"
            width="450"
            height="450"
        >
            <v-img
                :src="artworkMedium"
                contain
                v-if="artworkMedium"
            ></v-img>
        </v-dialog>

    </div>
</template>

<script>
    import dayjs from 'dayjs';
    import duration from 'dayjs/plugin/duration';

    dayjs.extend(duration);

    export default {
        props: ['isPlaying', 'showId', 'ind', 'episode', 'availableLevels', 'levels', 'selectLevel', 'currentLevel', 'artworkThumbnail', 'artworkSmall', 'artworkMedium', 'metadata', 'currentSegment', 'totalSegments', 'blobServer', 'loading'],

        data: () => ({
            dialogs: {
                artwork: {
                    state: false,
                }
            }
        }),

        computed: {
            blobServerUrl() {
                return (this.blobServer.https ? 'https://' : 'http://') + this.blobServer.host + ':' + this.blobServer.port + this.blobServer.path;
            },

            currentPercentage: {
                get() {
                    return Math.ceil((this.metadata.currentTime / this.metadata.duration) * 100);
                },
                set(newValue) {
                    return newValue;
                }
            },

            currentBuffer() {
                return Math.ceil((this.metadata.buffered / this.metadata.duration) * 100);
            },

            currentTime() {
                return (this.metadata.currentTime >= 3600) ?
                    dayjs.duration(this.metadata.currentTime, 'seconds').format('H:mm:ss') :
                    dayjs.duration(this.metadata.currentTime, 'seconds').format('m:ss');
            },

            duration() {
                return (this.metadata.duration >= 3600) ?
                    dayjs.duration(this.metadata.duration, 'seconds').format('H:mm:ss') :
                    dayjs.duration(this.metadata.duration, 'seconds').format('m:ss');
            }
        },

        methods: {
            selectBitrate(level) {
                this.$emit('selectedlevel', level);
            },

            play() {
                this.$emit('play');
            },

            pause() {
                this.$emit('pause');
            },

            backward() {
                this.$emit('backward');
            },

            forward() {
                this.$emit('forward');
            },

            rewind() {
                this.$emit('rewind');
            },

            fastForward() {
                this.$emit('fastforward');
            },

            seek(value) {
                this.$emit('seekreq', Math.round((value / 100) * this.metadata.duration));
            }
        },

        mounted() {
        }
    }
</script>