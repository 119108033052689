<template>
  <div class="transcript-wrapper mt-3">

    <v-menu v-model="showTranscriptMenu" absolute offset-y :position-x="transcriptMenuX" :position-y="transcriptMenuY">
      <v-list>
        <v-list-item link @click="dialogs.editor.state = true">
          <v-list-item-title>Edit</v-list-item-title>
        </v-list-item>
        <v-list-item link @click="deleteFragmentConfirm(dialogs.editor.exchangeIndex)">
          <v-list-item-title>Delete</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>


    <div v-if="this.loading" class="d-flex flex-column fill-height align-center justify-center black">
      <!-- <v-progress-circular indeterminate size="60" color="accent2"></v-progress-circular> -->
      <!-- <div class="text-uppercase mt-5 caption">Loading</div> -->

      <v-img src="@/assets/stella_large.gif" contain max-width="250" v-if="showStella"></v-img>
    </div>

    <div v-else-if="items.length === 0">
      <div class="font-italic text-center ma-5">Select a transcript under <strong>Versions</strong> to edit</div>
    </div>

    <div v-else v-for="(item, exchangeIndex) in items" :key="item.start" class="my-5 pr-3 text-justify">
      <div class="font-weight-bold" v-if="item[0]">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="secondary" dark text small class="px-1 py-0" v-bind="attrs" v-on="on">
              {{ getSpeaker(item[0].voice) }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(speaker, index) in speakerNames" :key="index" link
              @click="changeSpeaker(exchangeIndex, speaker)">
              <v-list-item-title>{{ getSpeaker(speaker.value) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <small>&nbsp;|&nbsp;&nbsp;{{ getTimeCode(item[0].start) }}</small>
      </div>

      <span class="fragment mr-1" v-for="fragment in item.map((o) => {
      return {
        start: o.start,
        text: stripHTML(o.text),
      };
    })" :key="fragment.start" :ref="`fragment-${exchangeIndex}`" v-html="fragment.text"
        @click="seekToCaption(fragment)" @contextmenu.prevent="openTranscriptMenu(fragment, exchangeIndex, $event)">
      </span>
    </div>

    <v-dialog v-model="dialogs.editor.state" max-width="700">
      <v-card>
        <v-toolbar dark dense color="primary">
          <v-toolbar-title>
            <v-icon class="mr-2">mdi-pencil</v-icon>Edit Caption
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogs.editor.state = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-5" v-if="dialogs.editor.fragment">
          <v-text-field v-model="dialogs.editor.fragment.text" label="Caption" dense hide-details outlined autofocus>
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogs.editor.state = false">Cancel</v-btn>
          <v-btn text color="secondary" @click="saveFragment(dialogs.editor.exchangeIndex)">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
.transcript-wrapper {
  height: 400px;
  overflow-y: auto;
}

.fragment:hover {
  background-color: rgba(255, 0, 0, 0.5);
  cursor: pointer;
}

.fragment.active {
  background-color: rgba(255, 0, 0, 0.75);
}
</style>

<script>
import { isNaN, isArray, findIndex, extend, isUndefined } from "lodash";
import dayjs from "dayjs";

export default {
  props: ['items', 'speakerNames', 'currentTime', 'loading', 'isDirtyProp'],

  data: () => ({
    showTranscriptMenu: false,
    transcriptMenuX: 0,
    transcriptMenuY: 0,
    isDirty: false,
    dialogs: {
      editor: {
        state: false,
        exchangeIndex: null,
        fragment: null
      }
    }
  }),

  computed: {
    showStella() {
      return this.$vuetify.theme.themes.name === 'sxm' && this.$vuetify.theme.themes.name === 'sxmlegacy';
    }
  },

  watch: {
    isDirty() {
      //notify the parent that our isDirty flag changed due to an edit
      if (!isUndefined(this.isDirty)) this.$emit('isDirty', this.isDirty);
    },

    isDirtyProp() {
      //load the value from the component prop
      this.isDirty = this.isDirtyProp;
    },

    currentTime() {
      if (isArray(this.items)) {
        for (let [exchangeIndex, itemsExchange] of this.items.entries()) {

          const foundIndex = findIndex(itemsExchange, o => {
            return (
              this.currentTime >= Math.round(o.start / 1000) &&
              this.currentTime < Math.round(o.end / 1000)
            );
          });

          for (const [index, fragment] of this.$refs['fragment-' + exchangeIndex].entries()) {
            //only update classes if necessary
            const hasActiveClass = fragment.classList.value.indexOf('active') !== -1;
            if (foundIndex !== -1 && index === foundIndex) {
              if (!hasActiveClass) {
                fragment.classList.value = 'fragment mr-1 active';

                //scroll to active fragment
                this.$vuetify.goTo(fragment, { container: ".transcript-wrapper" });
              }
            } else {
              if (hasActiveClass) fragment.classList.value = 'fragment mr-1';
            }
          }
        }
      }
    }
  },

  methods: {
    getTimeCode(ms) {
      return dayjs.duration(ms, "milliseconds").format("HH:mm:ss");
    },

    getSpeaker(voice) {
      return !isNaN(parseInt(voice)) ? "Speaker " + (parseInt(voice) + 1) : voice;
    },

    stripHTML(str) {
      if (typeof str !== "string") return str;
      const regex = /(<([^>]+)>)/gi;
      return str.replace(regex, "");
    },

    seekToCaption(item) {
      this.$emit("seekreq", item);
      return false;
    },

    saveFragment(exchangeIndex) {
      //find the fragment in the items array, but indexed by the speaker exchange since we are grouped by speaker
      const foundIndex = findIndex(this.items[exchangeIndex], { start: this.dialogs.editor.fragment.start });

      if (foundIndex !== -1) {
        // $set is needed for Vue to notice the change to a nested array, and extend is used to add/update 
        // the voice property with the existing caption object
        this.$set(this.items[exchangeIndex], foundIndex, extend(this.items[exchangeIndex][foundIndex], { text: this.dialogs.editor.fragment.text }))
        this.dialogs.editor.state = false;
        this.isDirty = true;
      }
    },

    deleteFragmentConfirm(exchangeIndex) {
      const confirm = window.confirm("Are you sure you want to delete this caption?");

      if (confirm) this.deleteFragment(exchangeIndex);
    },

    deleteFragment(exchangeIndex) {
      //find the fragment in the items array, but indexed by the speaker exchange since we are grouped by speaker
      const foundIndex = findIndex(this.items[exchangeIndex], { start: this.dialogs.editor.fragment.start });

      if (foundIndex !== -1) {
        this.items[exchangeIndex].splice(foundIndex, 1);
        this.isDirty = true;
      }
    },

    changeSpeaker(exchangeIndex, speaker) {
      // loop through all captions in the current exchange (single-voice sequence) and update the speaker
      for (const i in this.items[exchangeIndex]) {
        // $set is needed for Vue to notice the change to a nested array, and extend is used to add/update 
        // the voice property with the existing caption object
        this.$set(this.items[exchangeIndex], i, extend(this.items[exchangeIndex][i], { voice: speaker.text }))
      }

      this.isDirty = true;
    },

    openTranscriptMenu(fragment, exchangeIndex, e) {
      this.showTranscriptMenu = false;
      this.transcriptMenuX = e.clientX;
      this.transcriptMenuY = e.clientY;
      this.dialogs.editor.exchangeIndex = exchangeIndex;
      this.dialogs.editor.fragment = fragment;
      this.$nextTick(() => {
        this.showTranscriptMenu = true;
      });
    }
  },

  beforeMount() {
    //load the value from the component prop
    this.isDirty = this.isDirtyProp;
  }
};
</script>